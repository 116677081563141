import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';

import Slide1 from 'src/slides/desktop/en/chapter_4/sub_1/slide1';
import Slide2 from 'src/slides/desktop/en/chapter_4/sub_1/slide2';

import WrapperMobile from 'src/slides/mobile/en/chapter_4/sub_1/wrapperMobile';

import { isMobile } from 'react-device-detect';
import SEO from 'src/components/_shared/seo';


const allSlides = [<Slide1 />, <Slide2 />];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="The Bolshevik offensive | The Battle of Warsaw" lang="en" description="Over the body of White Poland to the global revolution" />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

const Mobile = () => (
  <>
    <SEO title="The Bolshevik offensive | The Battle of Warsaw" lang="en" description="Over the body of White Poland to the global revolution" />
    <WrapperMobile />
  </>
);

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
